<template>
  <div style="display:flex;">
    <span class="step-number">{{ step }}</span>
    <div>
      <div class="step-title">
        {{ title }}
      </div>
      <div class="step-text">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepItem',
  props: {
    step: {
      type: String,
      default: '01',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>
.step-title {
  font-weight: 700;
  padding: 0 10px 0 0;
}

.step-text {
  font-size: 0.82rem;
}

.step-number {
  font-size: 3rem;
  padding: 0 10px;
  font-weight: 650;
  line-height: 0.8;
  background-color: #f3ec78;
  background-image: linear-gradient(90deg, #ffe7b9, #f8ac18);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
</style>
