<style scoped>
.social-logos {
  font-size: 1.5rem;
}

.social-logos svg {
  margin-left: 30px;
}
</style>

<template>
  <div>
    <div class="site-container">
      <home-banner />
    </div>

    <div class="site-container">
      <step-banner />
    </div>

    <div class="site-container">
      <divider icon="arrow-down" class="my-4" />
    </div>

    <!-- BG LOGO -->
    <div class="site-container">
      <div class="bg-logo">
        <div class="text-center">
          <div class="quote mt-2">
            Na DIVVY, com apenas<br><br>
            <span class="big">R$ 4.000,00</span><br><br>
            você já pode ser dono da sua cota 
            <br>de um imóvel na planta!
          </div>
          <div class="mt-4">&nbsp;</div>
          <div class="mb-2" style="font-size: 1.2rem">A gente explica melhor</div>
          <div class="site-container">
            <div class="site-content">
              <div style="width: 100%; height: 40vw; max-height: 500px">
                <youtube color="#F8AC18" src="https://www.youtube.com/embed/gkfwhMRhixc" />
              </div>
            </div>
          </div>
          <div class="mt-4">&nbsp;</div>
          <!-- <b-button class="mb-4 btn-lg" variant="outline-dark" @click="() => goto('/')">Saiba Mais</b-button> -->
        </div>
      </div>
    </div>

    <div class="m-5">&nbsp;</div>

    <div class="site-container">
      <b-row>
        <b-col>
          <h2 class="quote">Como funciona o Investimento?</h2>
        </b-col>
        <b-col>&nbsp;</b-col>
      </b-row>
      <b-row class="how-works">
        <b-col sm="auto" class="how-works-1">
          <p>
            A Divvy estuda e analisa as melhores oportunidades do mercado imobiliário. As construtoras disponibilizam os imóveis selecionados pela Divvy para serem ofertados dentro da plataforma
            online. A Divvy realiza a divisão do imóvel em cotas e disponibiliza as cotas para serem vendidas dentro de sua plataforma para os investidores.
          </p>
          <p>Os investidores interessados entram na plataforma, realizam o cadastro e fazem a seleção e compra das cotas dos imóveis que forem de sua preferência.</p>
          <p>
            Após a compra das cotas do imóvel na planta, o investidor acompanha dentro da plataforma online a evolução da obra e a valorização do seu investimento. Assim que o investimento atingir sua
            rentabilidade ideal, é iniciada a venda deste imóvel em sua totalidade através dos corretores e imobiliárias parceiras da Divvy.
          </p>
          <p>
            Uma vez que é efetivada a venda do imóvel em sua totalidade, a plataforma da Divvy realiza a formalização dos contratos, abatimento dos custos, e repassa os lucros para os investidores.
          </p>
        </b-col>
        <b-col sm="auto" class="how-works-2">
          <div style="display: flex; align-items: center">
            <h3><b>Por que investir na Divvy?</b></h3>
            <quote-yellow-blur style="z-index: -1; margin-top: -60px; margin-left: -30px" />
          </div>
          <div style="display: flex">
            <h2 style="margin-right: 25px"><b>01</b></h2>
            <p>
              Acesso a uma plataforma inovadora de investimentos imobiliários, com disponibilização das análises de investimento, projeções financeiras, relatórios da evolução da obra e acompanhamento
              da valorização dos seus investimentos.
            </p>
          </div>
          <div style="display: flex">
            <h2 style="margin-right: 25px"><b>02</b></h2>
            <p>Preços acessíveis para se investir em imóveis na planta, com uma alta qualidade de portfólio e diversificação.</p>
          </div>
          <div style="display: flex">
            <h2 style="margin-right: 25px"><b>03</b></h2>
            <p>Oportunidade de investir em uma das melhores margens de rentabilidade dentro do mercado imobiliário.</p>
          </div>
          <div style="display: flex">
            <h2 style="margin-right: 25px"><b>04</b></h2>
            <p>Assessoria Divvy em todo processo para ajudá-lo, desde o cadastro até a realização do seu investimento.</p>
          </div>
          <div style="display: flex">
            <h2 style="margin-right: 25px"><b>05</b></h2>
            <p>Desburocratização de todo processo. Na Divvy tudo é feito de forma 100% online, de uma maneira fácil, rápida e segura.</p>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="site-container my-5">
      <action-flux />
    </div>

    <!-- ORANGE CONTAINER -->
    <!--div class="orange text-white">
      <div class="site-container">
        <b-row class="py-5">
          <b-col lg="4">
            <div style="height: 100%; display: grid">
              <icon-trending-up class="mb-2" />
              <p style="color: #fff; font-size: 1.8rem; line-height: 1.2; font-weight: 600">Conheça nossas oportunidades de investimento!</p>
              <p style="color: #fff">Oportunidades que unem a segurança do mercado imobiliário com a transparência do mercado financeiro para o maior número de pessoas.</p>
              <parceiros />
              <div style="display: flex; align-items: flex-end">
                <b-button class="btn-lg" style="margin-top: auto" variant="outline-light" @click="() => goto('/')">Ver Todos</b-button>
              </div>
            </div>
          </b-col>
          <b-col lg="8">
            <slider-imoveis :items="3" style="" />
          </b-col>
        </b-row>
      </div>
    </div-->

    <!-- TEXT -->
    <div class="site-container">
      <div class="text-center">
        <div class="quote">
          <icon-mug-hot class="mb-1" /><br>
          Aprenda mais sobre o mundo<br>
          dos investimentos!
        </div>
      </div>
    </div>

    <!-- BLOG 3 ITEMS -->
    <div class="site-container">
      <card-blog-static />
    </div>

    <!-- VIEW MORE BUTTON -->
    <div class="text-center p-5">
      <b-button class="mb-4 btn-lg" variant="outline-dark" @click="() => goto('/blog')">Saiba Mais</b-button>
    </div>

    <!-- DARK CONTAINER -->
    <!-- temp
    <div class="bg-dark text-white" style="padding-top: 80px; padding-bottom: 180px">
      <div class="site-container"> -->
    <!--icon-message style="margin-top: -25px; margin-bottom: 25px" /-->
    <!--p style="font-size: 1.6rem; font-weight: 600">Divvy na mídia</p-->
    <!--<slider-quotes :items="3" />
      </div>
    </div>-->

    <!-- ORANGE CARD -->
    <div class="site-container">
      <newsletter-card style="margin-top: -80px" />
    </div>

    <Footer />
  </div>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'

// import { slider, slideritem } from 'vue-concise-slider'

import Divider from '../components/Divider.vue'
import HomeBanner from '../components/HomeBanner.vue'
import StepBanner from '../components/step-banner/StepBanner.vue'

import CardBlogStatic from '../components/cards/CardBlogStatic.vue'

// import SliderQuotes from '../components/SliderQuotes.vue'
import IconMugHot from '../components/icons/IconMugHot.vue'
import Footer from '../components/parts/footer/Footer.vue'
import NewsletterCard from '../components/NewsletterCard.vue'
import Youtube from '../components/Youtube.vue'

import QuoteYellowBlur from '../components/images/QuoteYellowBlur.vue'
import ActionFlux from '../components/ActionFlux.vue'

export default {
  name: 'Home',
  components: {
    CardBlogStatic,
    StepBanner,
    HomeBanner,
    BButton,
    Divider,
    BRow,
    BCol,
    //  SliderImoveis,
    // SliderQuotes,
    //  IconTrendingUp,
    IconMugHot,
    //  IconMessage,
    //  Parceiros,
    Footer,
    NewsletterCard,
    Youtube,
    QuoteYellowBlur,
    ActionFlux,
  },

  methods: {
    prev() {
      this.$refs.slider.$emit('slideNext')
    },

    goto(url) {
      window.location.href = url
    },
  },
}
</script>

<style scoped>
.quote {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
}

.big {
  color: #f8ac18;
  font-weight: 700;
  font-size: 3.7rem;
}

.bg-logo {
  background-image: url('../assets/images/bg-logo.png');
  background-repeat: repeat-x;
  background-position: left;
  background-size: 500px;
}

.orange {
  background: #f8ac18;
}
@media (min-width: 1000px) {
  .how-works{
    display: flex;
  }
  .how-works-1 {
    width: 49%;
  }
  .how-works-2 {
    width: 49%;
  }
}
</style>
