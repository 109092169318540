<template>
  <svg :width="76 * scale" :height="60 * scale" viewBox="0 0 86 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_880:8040)">
      <path d="M22.078 70C16.8561 70 12.8709 67.9848 10.1226 63.9544C7.37419 59.924 6 54.2258 6 46.8599C6 28.6536 14.52 15.0337 31.5599 6L37.3315 16.6319C31.5599 19.9674 27.2312 23.6504 24.3454 27.6808C21.597 31.7112 20.2228 36.7839 20.2228 42.899C20.6351 42.76 21.2535 42.6906 22.078 42.6906C25.7883 42.6906 28.9489 43.8719 31.5599 46.2345C34.3083 48.4582 35.6825 51.5852 35.6825 55.6156C35.6825 60.063 34.377 63.607 31.766 66.2476C29.2925 68.7492 26.0631 70 22.078 70ZM64.7465 70C59.5246 70 55.5395 67.9848 52.7911 63.9544C50.0427 59.924 48.6685 54.2258 48.6685 46.8599C48.6685 28.6536 57.1885 15.0337 74.2284 6L80 16.6319C74.2284 19.9674 69.8997 23.6504 67.0139 27.6808C64.2656 31.7112 62.8914 36.7839 62.8914 42.899C63.3036 42.76 63.922 42.6906 64.7465 42.6906C68.4568 42.6906 71.6175 43.8719 74.2284 46.2345C76.9768 48.4582 78.351 51.5852 78.351 55.6156C78.351 60.063 77.0455 63.607 74.4345 66.2476C71.961 68.7492 68.7317 70 64.7465 70Z" fill="#FFD990" />
    </g>
    <defs>
      <filter id="filter0_f_880:8040" x="0" y="0" width="86" height="76" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_880:8040" />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'QuoteYellowBlur',
  props: {
    scale: {
      type: Number,
      default: 1.0,
    },
  },
}
</script>
