<style scoped>
.bg-icon {
  padding: 14px;
  box-shadow: 0px 4px 24px 4px rgba(183, 189, 208, 0.15);
  border-radius: 50%;
}
</style>

<template>
  <table class="dv">
    <tr>
      <td><div class="divider">&nbsp;</div></td>
      <td v-if="icon" class="td-icon">
        <span class="bg-icon" :style="{ backgroundColor: bg }">

          <!-- ARROW DOWN -->
          <svg v-if="icon == 'arrow-down'" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 5L12 19" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19 12L12 19L5 12" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <!-- TRENDING UP -->
          <svg v-if="icon == 'trending-up'" width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="27" cy="27" r="27" fill="white" fill-opacity="0.3" />
            <path d="M38.875 19.75L28.1875 30.4375L22.5625 24.8125L14.125 33.25" stroke="white" stroke-width="2.16" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M32.125 19.75H38.875V26.5" stroke="white" stroke-width="2.16" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </span>
      </td>
      <td><div class="divider">&nbsp;</div></td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'Divider',

  components: {},

  props: {
    icon: {
      type: String,
      default: 'arrow-down',
    },
    bg: {
      type: String,
      default: '#ffffff',
    },
    color: {
      type: String,
      default: '#F8AC18',
    },
  },
}
</script>

<style>
.dv {
  width: 90%;
  margin: 0 auto;
}

.dv td {
  text-align: center;
}

.td-icon {
  width: 80px;
}

/* .divider {
  background: #ddd;
  margin: 50px 0;
  height: 1px;
} */

.divider-icon {
  box-shadow: 0 0 10px #eee;
  font-size: 3rem;
}
</style>
