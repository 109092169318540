<template>
  <svg width="42" height="42" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="27" cy="27" r="27" fill="#FFEDCB" />
    <path d="M34 22.3333H35.1667C36.4043 22.3333 37.5913 22.825 38.4665 23.7001C39.3417 24.5753 39.8333 25.7623 39.8333 27C39.8333 28.2377 39.3417 29.4246 38.4665 30.2998C37.5913 31.175 36.4043 31.6666 35.1667 31.6666H34" stroke="#F8AC18" stroke-width="2.15385" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.3333 22.3333H33.9999V32.8333C33.9999 34.071 33.5083 35.258 32.6331 36.1331C31.7579 37.0083 30.5709 37.5 29.3333 37.5H19.9999C18.7622 37.5 17.5753 37.0083 16.7001 36.1331C15.8249 35.258 15.3333 34.071 15.3333 32.8333V22.3333Z" stroke="#F8AC18" stroke-width="2.15385" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M20 14.1667V17.6667" stroke="#F8AC18" stroke-width="2.15385" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M24.6667 14.1667V17.6667" stroke="#F8AC18" stroke-width="2.15385" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M29.3333 14.1667V17.6667" stroke="#F8AC18" stroke-width="2.15385" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'IconMugHot',
}
</script>
