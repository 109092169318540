<template>
  <div style="color: white; background-size: cover; background-position: center; border-radius: 12px" :style="{ backgroundImage: `url(${srcBanner})` }">
    <b-row class="site-content">
      <b-col cols="sm" style="padding: 40px 0">
        <div style="padding: 40px 0">
          <p class="banner-title">Investimentos imobiliários acessíveis a <span style="color: #f8ac18;">todos</span>.</p>
          <p class="banner-text">
            Simples, fácil e seguro para qualquer pessoa investir no mercado imobiliário como nunca antes feito. Uma total democratização do mercado, com a DIVVY você investe em cotas de imóveis na
            planta.
          </p>
          <b-button class="mt-1" variant="warning" size="md" @click="() => $router.push({ name: 'register-profile' })">Venha ser Divvy!</b-button>
        </div>
      </b-col>
      <b-col>&nbsp;</b-col>
    </b-row>
  </div>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'

// import mixin from '@/mixins'

const img = require('../assets/images/divvy-banner.png')

export default {
  // mixins: [mixin],

  components: {
    BButton,
    BRow,
    BCol,
  },

  data() {
    return {
      srcBanner: img,
    }
  },
}
</script>

<style scope>
@media screen and (min-width: 601px) {
  .banner-title {
    font-size: 50px;
  }
  .banner-text {
    font-size: 18px;
  }
  .content {
    padding: 5rem 0;
  }
}

@media screen and (max-width: 600px) {
  .banner-title {
    font-size: 28px;
  }
  .banner-text {
    font-size: 12px;
  }
  .content {
    padding: 5rem 0;
  }
}

.banner-title {
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.15rem;
}

.banner-text {
  font-weight: 200;
  width: 82%;
}

.banner-btn {
  padding: 18px 12px;
  letter-spacing: 0.05rem;
}

.banner-content {
  width: 500px;
}
</style>
