<template>
  <b-card class="site-content s-card">
    <b-row>
      <b-col class="dd-col"><StepItem step="01" title="Cadastre-se na DIVVY" text="Clique em VENHA SER DIVVY, e siga as instruções." /></b-col>
      <b-col class="dd-col"><StepItem step="02" title="Escolha seu investimento" text="Selecione o imóvel na planta que você mais gosta." /></b-col>
      <b-col class="dd-col"><StepItem step="03" title="Faça seu Checkout" text="Fácil, rápido e seguro, pague sua cota e aguarde a confirmação." /></b-col>
      <b-col class="dd-col"><StepItem step="04" title="Pronto! Você é DIVVY." text="Agora é só acompanhar seus investimentos." /></b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

import StepItem from './StepItem.vue'

export default {
  name: 'Home',

  components: {
    StepItem,
    BCard,
    BRow,
    BCol,
  },
}
</script>

<style scoped>
.dd-col {
  padding: 5px 0 !important;
}

@media screen and (min-width: 601px) {
  .s-card {
    margin: -60px auto 0 auto;
    padding: 10px;
  }
}

@media screen and (max-width: 600px) {
  .s-card {
    margin: 40px auto;
    padding: 10px;
  }
}
</style>
