<template>
  <b-row>
    <b-col v-for="(item, i) in blogList" :key="i" sm="4">
      <div class="d-card fadeIn" style="width: 100%; margin-top: 10px" :style="'background-image:url(' + item.image + ')'">
        <div class="d-card-content fadeIn">
          <div>
            <b-button pill variant="warning" class="d-pill" @click="gotoCateg(item.blogCategoryName)">{{ item.blogCategoryName }}</b-button>
          </div>
          <div style="margin-top: auto">
            <p style="font-size: 1.2rem; font-weight: 400">{{ item.title }}</p>
            <b-button pill variant="light" class="bg-transparent text-white btn-sm" @click="gotoItem(item.id)">Saiba mais</b-button>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
  },

  data() {
    return {
      blogList: [],
    }
  },

  async mounted() {
    this.blogList = await this.$store.dispatch('site/obterBlogs')
  },

  methods: {
    gotoCateg(name) {
      window.location.href = `/blog/${name}`
    },

    gotoItem(id) {
      window.location.href = `/blog-view/${id}`
    },
  },
}
</script>

<style>
.d-sign {
  color: black;
  font-family: AlexBrush;
  position: absolute;
  font-size: 150px;
  line-height: 0.7;
}

.d-card {
  text-align: left;
  border-radius: 12px;
  display: inline-block;
  background-size: cover;
  border: 1px solid #eee;
}

.d-card-content {
  background: linear-gradient(360deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0) 100%);
  background: rgb(0, 0, 0);
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  height: 330px;
  color: #fff;
  flex-direction: column;
  display: grid;
}

.d-pill {
  font-size: 0.7rem;
  padding: 6px 12px;
}
</style>

<style scoped lang="scss">
.swiper {
  // height: 300px;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    // text-align: center;
    font-weight: bold;
    font-size: 1rem * 2;
    background-color: white;
  }
}@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes fadeIn {
  0% { opacity: 0;}
  100% { opacity: 1; }
}
@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.fadeIn {
  -webkit-animation: fadeIn 3s ease-in-out;
  -moz-animation: fadeIn 3s ease-in-out;
  -o-animation: fadeIn 3s ease-in-out;
  animation: fadeIn 3s ease-in-out;
}
</style>
